const Menu = ({ menu }) => {
  return (
    <section className="customauto-displayData">
      <div className="customauto-mainsection">
        <div className="customauto-topheader">
          <h1>LUNCH / LOUNAS</h1>
          <h2>Week {menu?.viikko}</h2>
        </div>

        <div className="customauto-contentmainsection">
          <div className="customauto-item-center">
            <div className="customauto-subsection">
              <h1>CHOOSE A BURGER / Valitse hampurilainen</h1>
              <ul>
                <li>
                  <div className="custom-auto-listitems-heading-left">#1</div>
                  <div className="custom-auto-listitems-details-left">
                    <h3 id="bbq-smash-2">{menu?.burger_1}</h3>
                    <p id="SINGLE-SMASH-PATTY---2">{menu?.burger_1_info}</p>
                  </div>
                </li>
                <li>
                  <div className="custom-auto-listitems-heading-left">#2</div>
                  <div className="custom-auto-listitems-details-left">
                    <h3 id="bbq-smash-1">{menu?.burger_2}</h3>
                    <p id="SINGLE-SMASH-PATTY---1">{menu?.burger_2_info}</p>
                  </div>
                </li>
                <li>
                  <div className="custom-auto-listitems-heading-left">#3</div>
                  <div className="custom-auto-listitems-details-left">
                    <h3 id="bbq-smash">{menu?.burger_3}</h3>
                    <p id="SINGLE-SMASH-PATTY--">{menu?.burger_3_info}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="customauto-item-center">
            <div className="customauto-subsection">
              <h1>PICK A SIDE / Valitse lisuke </h1>
              <ul>
                <li>
                  <div className="custom-auto-listitems-heading-right">#1</div>
                  <div className="custom-auto-listitems-details-right">
                    <h3 id="FRIES---HOUSE-MAYO">{menu?.lisa_1}</h3>
                  </div>
                </li>
                <li>
                  <div className="custom-auto-listitems-heading-right">#2</div>
                  <div className="custom-auto-listitems-details-right">
                    <h3 id="HOUSE-SLAW">{menu?.lisa_2}</h3>
                  </div>
                </li>
                <li>
                  <div className="custom-auto-listitems-heading-right">#3</div>
                  <div className="custom-auto-listitems-details-right">
                    <h3 id="MIXED-SALAD">{menu?.lisa_3}</h3>
                  </div>
                </li>
              </ul>
            </div>

            <div className="customauto-pricefooter">
              <h1>12.70€</h1>
              <p>INCLUDES COFFEE / TEA</p>
              <p>Sisältää kahvin / Teen</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Menu;
