import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { initializeApp } from "firebase/app";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);

const firebaseConfig = {
  apiKey: "AIzaSyBEaB8YhsH09kPMJroXUdqrtLvuwrFItXc",
  authDomain: "bites-lounas-dev.firebaseapp.com",
  projectId: "bites-lounas-dev",
  storageBucket: "bites-lounas-dev.appspot.com",
  messagingSenderId: "252113462766",
  appId: "1:252113462766:web:edd45d6dbb6beb9a839aff",
  measurementId: "G-BJ7Q1MX2ZL",
};

const app = initializeApp(firebaseConfig);
